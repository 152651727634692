import type { FC } from 'react';
import { useState } from 'react';
import { useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Button, Heading, Icon, ThumbsUpToastImage } from '@pafcloud/base-components';
import { Color, FontTextSize, NoMotionPreference } from '@pafcloud/style';
import { useFlowRouter } from '@pafcloud/flow-router';
import { useTranslation } from '@pafcloud/i18n';
import { BonusBannerImage } from '../flow';
import { GeneratedBonusTitle } from '../GeneratedBonusTitle';
import { BonusColors } from '../bonus-colors';
import { JoinButton, programGroupItemFragment } from './shared';
import type { ProgramGroupItemProps } from './shared';
import { useHandleJoinProgramInGroup } from './useHandleJoinProgramInGroup';

const ProgramGroupCard = styled.article<{ disableClick?: boolean }>(
  {
    position: 'relative',
    borderRadius: 8,
    overflow: 'hidden',
    cursor: 'pointer',

    picture: {
      marginTop: 32,

      img: {
        [NoMotionPreference]: {
          transition: 'transform .2s ease-in',
        },
      },
    },

    ':hover, :active, :focus, :focus-within': {
      boxShadow: `0 0 8px 8px ${Color.Primitive.Primary}`,

      img: {
        transform: 'scale(1.1)',
      },
    },
  },
  ({ disableClick }) =>
    disableClick
      ? {
          filter: 'grayscale(1)',
          cursor: 'default',

          ':hover, :active, :focus': {
            boxShadow: 'none',
            img: {
              transform: 'none',
            },
          },
        }
      : null,
);

const TitleBar = styled.div({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto auto',
  padding: 8,
  minHeight: 32,
  width: '100%',
  background: BonusColors.CardBackground,
  color: BonusColors.Text,
});

const Title = styled(Heading)({
  margin: 0,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: '1.2em',
  textOverflow: 'ellipsis',
  textWrap: 'balance',
  overflow: 'hidden',
  fontSize: FontTextSize.Normal,
});

const BlurredProgramGroupCard = styled(ProgramGroupCard)({
  cursor: 'default',

  picture: {
    filter: 'grayscale(1) brightness(0.5)',

    // Since this is set to not animate above it feels janky if it's not persisting
    // its animated state after click
    img: {
      transform: 'scale(1.1)',
    },
  },

  [`${Icon}`]: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1,
  },

  // These two solve targetting errors, eg.:
  // ReferenceError: Cannot access 'JoinButton' before initialization
  'button:first-of-type': {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translate(-50%, -50%)',
    left: '50%',
    backgroundColor: Color.Layout.Background,
  },

  'button:last-of-type': {
    bottom: 24,
    zIndex: 1,
    width: '50%',
  },
});

export const HorizontalProgramGroupItem: FC<ProgramGroupItemProps> = (props) => {
  const offer = useFragment(programGroupItemFragment, props.offer);
  const { t } = useTranslation(['bonus', 'common', 'inbox']);
  const [isLoading, setIsLoading] = useState(false);
  const { openFlow } = useFlowRouter();

  const handleJoinProgram = useHandleJoinProgramInGroup({
    externalId: props.externalId,
    groupId: props.groupId,
    offerId: props.offerId,
    setIsLoading,
    thumbsUpToastImage: <ThumbsUpToastImage altText={t('common:thumbs-up-toast-image.alt')} />,
  });

  const isOffer = props.offerId != null && props.groupId != null;

  const showProgram = () => {
    return openFlow('bonus', {
      programExternalId: props.externalId,
      group: isOffer ? undefined : true,
      fromGroup: isOffer ? true : undefined,
    });
  };

  const handleCardClick = () => {
    if (!isOffer) {
      return showProgram();
    }
  };

  if (isOffer && !props.disableClick) {
    return (
      <BlurredProgramGroupCard>
        <TitleBar>
          {offer.title ? <Title>{offer.title}</Title> : <GeneratedBonusTitle offer={offer} styledComponent={Title} />}
        </TitleBar>
        <Button variant="secondary" size="small" type="button" onClick={showProgram}>
          {t('inbox:bonus.open-flow.button.text')}
        </Button>
        <JoinButton
          variant="primary"
          size="default"
          type="button"
          onClick={handleJoinProgram}
          isLoading={isLoading}
          autoFocus
        >
          {t('bonus:join.button.text')}
        </JoinButton>
        <BonusBannerImage bannerImage={offer?.bannerImage} offer={offer} />
      </BlurredProgramGroupCard>
    );
  }

  return (
    <ProgramGroupCard
      onClick={props.disableClick ? undefined : handleCardClick}
      tabIndex={props.disableClick ? -1 : 0}
      disableClick={props.disableClick}
    >
      <TitleBar>
        {offer.title ? <Title>{offer.title}</Title> : <GeneratedBonusTitle offer={offer} styledComponent={Title} />}
      </TitleBar>
      <BonusBannerImage bannerImage={offer?.bannerImage} offer={offer} />
    </ProgramGroupCard>
  );
};

import { $buildEnv } from '@pafcloud/config/src/buildEnv';

export const resolveBackgroundImages = () => {
  if ($buildEnv.site === 'x3000.com') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'x3000.ee') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'x3000.lv') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/x3000-discover-weekly-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'speedybet.com') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/speedy-bet-com.jpeg',
      mobileImageUrl: '/static/images/hero-backgrounds/speedy-bet-com-mobile.jpeg',
    };
  }

  if ($buildEnv.site === 'speedycasino.com') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/speedy-casino.jpeg',
      mobileImageUrl: '/static/images/hero-backgrounds/speedy-casino-mobile.jpeg',
    };
  }

  if ($buildEnv.site === 'mycasino.ch') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/mycasino-game-item.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/mycasino-game-item-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'prankcasino.se') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/prank.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/prank-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'pinatacasino.es') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/pinata.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/pinata-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'goldenbull.es') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/golden-coins-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/golden-coins-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'goldenbull.se') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/golden-coins-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/golden-coins-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'noaccountcasino.se') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/golden-coins-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/golden-coins-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'noaccountbet.se') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/golden-coins-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/golden-coins-mobile.jpg',
    };
  }

  if ($buildEnv.site === '11.lv') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/11-basic-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/11-basic-mobile.jpg',
    };
  }

  if ($buildEnv.site === 'speedybet.es') {
    return {
      desktopImageUrl: '/static/images/hero-backgrounds/speedy-bet-hero-desktop.jpg',
      mobileImageUrl: '/static/images/hero-backgrounds/speedy-bet-hero-mobile.jpg',
    };
  }

  return {
    desktopImageUrl: '/static/images/hero-backgrounds/paf-game-item.jpg',
    mobileImageUrl: '/static/images/hero-backgrounds/paf-game-item-mobile.jpg',
  };
};

import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useClaimRewardMutation } from './__generated__/useClaimRewardMutation.graphql';

const createUseClaimReward = createUseMutation<useClaimRewardMutation>(graphql`
  mutation useClaimRewardMutation($input: ClaimRewardInput!) {
    claimReward(input: $input) {
      success
    }
  }
`);

export const useClaimReward = createUseClaimReward(() => {
  return null;
});

/**
 * @generated SignedSource<<1e2bf4becdb1a5b55005d8093ded97fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramGroupBlock_block$data = {
  readonly program: {
    readonly " $fragmentSpreads": FragmentRefs<"useProgramGroupProperties_program">;
  } | null;
  readonly " $fragmentType": "ProgramGroupBlock_block";
};
export type ProgramGroupBlock_block$key = {
  readonly " $data"?: ProgramGroupBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramGroupBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramGroupBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PublicBonusOffer",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useProgramGroupProperties_program"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProgramGroupBlock",
  "abstractKey": null
};

(node as any).hash = "d8c9a5d3765fdf283b29702a92db8be4";

export default node;

import type { FC } from 'react';
import styled from '@emotion/styled';
import { FullWidthImage, HorizontalScroller, ThumbsUpToastImage } from '@pafcloud/base-components';
import { removeNullValues } from '@pafcloud/collection-utils';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint } from '@pafcloud/style';
import { useBonus } from '@pafcloud/contexts';
import { resolveBackgroundImages } from './resolveBackgroundImages';
import { HorizontalProgramGroupItem } from './HorizontalProgramGroupItem';
import { JoinButton } from './shared';
import { useProgramGroupProperties } from './useProgramGroupProperties';
import type { ProgramGroupProps, RenderPosition } from './useProgramGroupProperties';

const ProgramContainer = styled.div<{
  firstInMain?: boolean;
  renderPosition: RenderPosition | undefined;
}>(
  {
    position: 'relative',
    padding: '48px 0',

    'button[direction]': {
      '--nav-button-icon': 'white',
      '--nav-button-icon-glow': 'black',
    },
  },
  ({ renderPosition }) => {
    if (renderPosition === 'HERO') {
      return {
        width: 'calc(100% + (var(--page-margin) * 2))',
      };
    }

    if (renderPosition === 'FLOW') {
      return {
        // Reset side margins for scroller when program group is rendered in a flow
        '--full-width-margin': 'var(--side-margin)',
        marginLeft: 'calc(var(--side-margin) * -1)',
        marginRight: 'calc(var(--side-margin) * -1)',
      };
    }
  },

  ({ firstInMain }) =>
    firstInMain && {
      marginTop: 0, // remove default top margin if this block is rendered first.
    },
);

const WidthBoundaries = styled.div({
  [Breakpoint.LaptopOrLarger]: {
    width: '100%',
    maxWidth: 'max(1000px, min(60vw, 100%))',
    margin: '0 auto',
  },
});

const Background = styled(FullWidthImage)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const ProgramHorizontalScroller = styled(HorizontalScroller)({
  '--number-of-items': 1,

  [Breakpoint.TabletOrLarger]: {
    '--number-of-items': 2,
  },
  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 3,
  },

  '[data-position="FLOW"] &': {
    '--number-of-items': 1.3,

    [Breakpoint.LaptopOrLarger]: {
      '--number-of-items': 2,
    },
  },
});

export const HorizontalProgramGroup: FC<ProgramGroupProps> = (props) => {
  const { t } = useTranslation(['bonus', 'common']);
  const { publicOfferIds } = useBonus();
  const programGroupProperties = useProgramGroupProperties({
    offer: props.offer,
    program: props.program,
    thumbsUpImage: <ThumbsUpToastImage altText={t('common:thumbs-up-toast-image.alt')} />,
  });

  if (programGroupProperties == null) {
    return null;
  }

  const { groupExternalId, offerId, programs, handleJoinProgram } = programGroupProperties;

  if (publicOfferIds.includes(groupExternalId)) {
    return null;
  }

  const { desktopImageUrl, mobileImageUrl } = resolveBackgroundImages();

  const renderPosition = (): RenderPosition | undefined => {
    if (props.renderPosition) {
      return props.renderPosition;
    }

    if (props.firstInMain) {
      return 'MAIN';
    }

    if (props.usedInHero) {
      return 'HERO';
    }

    return 'FLOW';
  };

  return (
    <ProgramContainer
      className={props.className}
      firstInMain={props.firstInMain}
      data-position={`${renderPosition()}`}
      renderPosition={renderPosition()}
    >
      {!props.usedInHero && <Background desktopImageUrl={desktopImageUrl} mobileImageUrl={mobileImageUrl} />}

      <WidthBoundaries>
        <ProgramHorizontalScroller>
          {removeNullValues(programs).map((playerProgram) => (
            <HorizontalProgramGroupItem
              key={playerProgram.externalId}
              externalId={playerProgram.externalId}
              groupId={groupExternalId}
              offerId={offerId}
              offer={playerProgram}
              disableClick={props.disableClick}
            />
          ))}
        </ProgramHorizontalScroller>
      </WidthBoundaries>
      {props.canJoinGroup && (
        <JoinButton variant="primary" size="default" type="button" onClick={handleJoinProgram}>
          {t('bonus:join.button.text')}
        </JoinButton>
      )}
    </ProgramContainer>
  );
};

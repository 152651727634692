import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { HorizontalProgramGroup } from './HorizontalProgramGroup';
import type { ProgramGroupBlock_block$key } from './__generated__/ProgramGroupBlock_block.graphql';

const blockFragment = graphql`
  fragment ProgramGroupBlock_block on ProgramGroupBlock {
    program {
      ...useProgramGroupProperties_program
    }
  }
`;

type Props = {
  block: ProgramGroupBlock_block$key | null;
  firstInMain?: boolean;
  usedInHero?: boolean;
};

export const ProgramGroupBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  if (block == null) {
    return null;
  }

  return (
    <HorizontalProgramGroup
      offer={null}
      program={block.program}
      className={props.usedInHero ? undefined : 'full-width'}
      firstInMain={props.firstInMain}
      usedInHero={props.usedInHero}
      canJoinGroup
      renderPosition={props.usedInHero ? 'HERO' : 'MAIN'}
    />
  );
};

/**
 * @generated SignedSource<<b9e56757469dd70d250e2ad23a645b38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BonusBannerImage_bannerImage$data = {
  readonly height: number;
  readonly url: string;
  readonly width: number;
  readonly " $fragmentType": "BonusBannerImage_bannerImage";
};
export type BonusBannerImage_bannerImage$key = {
  readonly " $data"?: BonusBannerImage_bannerImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BonusBannerImage_bannerImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BonusBannerImage_bannerImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
};

(node as any).hash = "51b0b2d862209d614cb56e3ff59a1fbf";

export default node;

import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useRouter } from 'next/router';
import { Image } from '@pafcloud/base-components';
import { BonusFallbackImage } from '../BonusFallbackImage';
import type { BonusBannerImage_bannerImage$key } from './__generated__/BonusBannerImage_bannerImage.graphql';
import type { BonusBannerImage_offer$key } from './__generated__/BonusBannerImage_offer.graphql';
import { BannerPicture } from './bonusFlowStyles';

const bannerImageFragment = graphql`
  fragment BonusBannerImage_bannerImage on Image {
    url
    height
    width
  }
`;

const offerFragment = graphql`
  fragment BonusBannerImage_offer on IBonusOffer {
    ...BonusFallbackImage_offer
  }
`;

type Props = {
  bannerImage: BonusBannerImage_bannerImage$key | null;
  offer: BonusBannerImage_offer$key | null;
};

export const BonusBannerImage: FC<Props> = (props) => {
  const { query } = useRouter();
  const isFlowContent = query.flowType?.includes('promotion');
  const sizes = isFlowContent ? '(max-width: 813px) 100vw, 770px' : '100vw';

  const bannerImage = useFragment(bannerImageFragment, props.bannerImage);
  const offer = useFragment(offerFragment, props.offer);

  if (bannerImage != null) {
    return (
      <BannerPicture data-testid="banner-image">
        <Image src={bannerImage.url} alt="" fill sizes={sizes} />
      </BannerPicture>
    );
  }

  if (offer != null) {
    return <BonusFallbackImage offer={offer} />;
  }

  return null;
};
